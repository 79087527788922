import * as React from "react";
import { hydrateRoot } from 'react-dom/client';
import ReactQueryClientProvider from "@contexts/QueryClientContext";
import { ErrorBoundary } from "react-error-boundary";
import HttpError from "@helper/httpError";
import { constructFullUrl } from "@helper/urlHelpers";

type ComponentNameType = 'Navigation' | 'DirectoryCard' | 'Footer' |
    'ImageGallery' | 'ImageSlider' | 'SupplierSearch' | 'BulkReviews' |
    'Messaging' | 'MessagingCount' | 'MessagingChannel' | 'MessagingDrawer' |
    'WeddingSupplierPackages' | 'ConciergeGeneric' | 'WedPoints' |
    'SalePoints' | 'AppBanner' | 'WedPointsEarned';
    // Do not forget to add the component to build/crtitical/components-defaults.js and also to vite.config.js

const COMPONENTS_LIST = window['SSR_COMPONENT_LIST'];

const onError = (error: Error) => {
    if (error instanceof HttpError) {
        if(error.status === 401) {
            document.location.href = "/login/";
        }
        // user's token is no longer valid, redirecting to login to authorise
        if(error.status === 401) {
            window.location.href = constructFullUrl('/logout/');
        }
    }
}

function ErrorRender() {
    return <div>Something went wrong, please <a href="#" onClick={() => document.location.reload()}>try again</a>.</div>
}

function hydrateComponents() {
    COMPONENTS_LIST.forEach(({ domID, name, data }: { domID: string, name: ComponentNameType, data: any }) => {
        const Component = React.lazy(() => import(`../../components/${name}.tsx`))
        if (typeof Component === 'object' || typeof Component === 'function') {
            const element = document.getElementById(domID) as Element

            if (!!element) {
                hydrateRoot(
                    element,
                    <React.StrictMode>
                        <ErrorBoundary onError={onError} fallbackRender={ErrorRender}>
                            <ReactQueryClientProvider>
                                <Component {...data} />
                            </ReactQueryClientProvider>
                        </ErrorBoundary>
                    </React.StrictMode>
                );
            }
            else {
                console.error(`${domID} element is not present on the page`)
            }
        } else {
            console.error(`${name} is not valid element`)
        }
    })
}
if (import.meta.env.SSR) {
    hydrateComponents()
} else {
    if (document.readyState === "complete" || document.readyState === "interactive") {
        hydrateComponents()
    } else {
        document.addEventListener('DOMContentLoaded', hydrateComponents)
    }
}